<template>
  <div class="opexReport">
    <div id="maintablepage">
      <div class="maintablepage-content">
        <button @click="handleOutput">生成PDF</button>
        <!-- 内容 -->
        <div class="contant_O pdf-panel" id="class_pdf">
          <headTitle :objAll="objAll"></headTitle>
          <directory :objAll="objAll" :nameList="nameList"></directory>
          <div class="maintablepage-header">
            <div class="maintablepage-header-name">运维报告</div>
          </div>
          <!-- 3.1 维修工单 KPI 指标分析 -->
          <div class="cont_z">
            <div class="msrStr">1. 维修工单 KPI 指标分析</div>
            <el-table class="app-table" :data="tableData" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="createbyname" width="300">
              </el-table-column>
              <!-- <el-table-column label="KPI 评分" style="display: none" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" style="display: none" align="center" prop="yoy">
                <template slot-scope="scope">
                  <div style="display: flex;justify-content: center;">
                    {{ scope.row.yoy + '%' }}
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column label="本期工单(条) " align="center" prop="wocount">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="yoy">
                <template slot-scope="scope">
                  <div style="display: flex;justify-content: center;">
                    <!-- 升 -->
                    <!-- <i class="el-icon-caret-top" style="color: red;font-size: 24px;line-height: 40px;"></i> -->
                    {{ scope.row.yoy + '%' }}
                    <!-- 降 -->
                    <!-- <i class="el-icon-caret-bottom" style="color: green;font-size: 24px;line-height: 40px;"></i> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="name">
              </el-table-column>
            </el-table>
            <div class="marStyle">单个维修工单 KPI 平均分，其分析曲线如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart1" id="chart1"></div>
            <div class="marStyle">统计维修工单总工时情况，具体构成如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart2" id="chart2"></div>
            <div class="marStyle">单个维修工单平均工时情况，具体构成如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart3" id="chart3"></div>
          </div>
          <!-- 3.2 维保工单 KPI 指标分析 -->
          <div class="cont_z">
            <div class="msrStr">2. 维保工单 KPI 指标分析 </div>
            <el-table class="app-table" :data="tableData1" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="createbyname" width="300">
              </el-table-column>
              <!-- <el-table-column label="KPI 评分" style="display: none" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" style="display: none" align="center" prop="yoy">
                <template slot-scope="scope">
                  <div style="display: flex;justify-content: center;">
                    {{ scope.row.yoy + '%' }}
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column label="本期工单(条) " align="center" prop="wocount">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="yoy">
                <template slot-scope="scope">
                  <div style="display: flex;justify-content: center;">
                    {{ scope.row.yoy + '%' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="name">
              </el-table-column>
            </el-table>
            <div class="marStyle">单个维保工单 KPI 平均分，其分析曲线如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart12" id="chart12"></div>
            <div class="marStyle">统计维保工单总工时情况，具体构成如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart13" id="chart13"></div>
            <div class="marStyle">单个维保工单平均工时情况，具体构成如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart14" id="chart14"></div>
          </div>
          <!-- 3.3 巡检工单 KPI 指标分析 -->
          <div class="cont_z">
            <div class="msrStr">3. 巡检工单 KPI 指标分析</div>
            <el-table class="app-table" :data="tableData2" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="createbyname" width="300">
              </el-table-column>
              <!-- <el-table-column label="KPI 评分" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="yoy">
                <template slot-scope="scope">
                  <div style="display: flex;justify-content: center;">
                    {{ scope.row.yoy + '%' }}
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column label="本期工单(条) " align="center" prop="wocount">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="yoy">
                <template slot-scope="scope">
                  <div style="display: flex;justify-content: center;">
                    <!-- 升 -->
                    <!-- <i class="el-icon-caret-top" style="color: red;font-size: 24px;line-height: 40px;"></i> -->
                    {{ scope.row.yoy + '%' }}
                    <!-- 降 -->
                    <!-- <i class="el-icon-caret-bottom" style="color: green;font-size: 24px;line-height: 40px;"></i> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="name">
              </el-table-column>
            </el-table>
            <div class="marStyle">单个巡检工单 KPI 平均分，其分析曲线如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart23" id="chart23"></div>
            <div class="marStyle">统计巡检工单总工时情况，具体构成如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart21" id="chart21"></div>
            <div class="marStyle">单个巡检工单平均工时情况，具体构成如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart22" id="chart22"></div>
          </div>
          <!-- 3.4 工单执行情况分析-->
          <div class="cont_z">
            <div class="msrStr">4. 工单执行情况分析</div>
            <el-table class="app-table" :data="tableData3" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="namecode" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div class="marStyle">统计工单完成情况，具体构成如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart33" id="chart33"></div>
            <div class="marStyle">工单按月分析，其变化曲线如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart44" id="chart44"></div>
          </div>
          <!-- 3.5 事件处理情况分析-->
          <div class="cont_z">
            <div class="msrStr">5. 事件处理情况分析</div>
            <el-table class="app-table" :data="tableData4" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="namecode" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div class="marStyle">统计事件完成情况，具体构成如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart4" id="chart4"></div>
            <div class="marStyle">事件按月分析，其变化曲线如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart5" id="chart5"></div>
          </div>
        </div>
        <!--  -->
        <div class="pdf-header"
          style="font-weight: bold; padding:15px 8px; width: 100%; color: rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
          <!-- 页头 -->
        </div>
        <div class="pdf-footer"
          style="font-weight: bold; padding: 15px 8px; width: 100%; border-top: 1px solid rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
          <div style="display: flex; justify-content: center; align-items: center; padding-top: 5px;">
            页尾
          </div>
          <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
            第<div class="pdf-footer-page"></div>页 / 共<div class="pdf-footer-page-count"></div>页
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headTitle from './homePage'
import directory from './directory'
// import PdfLoader from './html2pdf.js'
import { outputPDF } from './outputPDF'
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
  components: { headTitle, directory },
  data() {
    return {
      nameList: [
        {
          name: '运维报告',
          page: 2
        },
        {
          name: '维修工单 KPI 指标分析',
          page: 2
        },
        {
          name: '维保工单 KPI 指标分析',
          page: 3
        },
        {
          name: '巡检工单 KPI 指标分析',
          page: 3
        },
        {
          name: '工单执行情况分析',
          page: 4
        },
        {
          name: '事件处理情况分析',
          page: 5
        }
      ],
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [
        // {
        //   namecode: '预警（项）',
        //   name: 22,
        //   type: '21.2%',
        //   site: 1
        // },
        // {
        //   namecode: '告警（项）',
        //   name: 212,
        //   type: '21.2%',
        //   site: 1
        // }
      ],
      typeCntData: {},
      valueData: {},
      sumCountData: {},
      curData: {},
      swxtData: {},
      data2: [],
      objAll: {}
    }
  },
  created() {
    let allQuery = this.$route.query
    this.objAll = {
      title: allQuery.regionName + allQuery.region2 + '系统',
      times: this.$moment(allQuery.date1).format('YYYY [年] ') + (allQuery.region3 == '天' ? this.$moment(allQuery.date1).format("MMM Do") : (allQuery.region3 == '月' ? (this.$moment(allQuery.date1).format("MMM [月]") + '度') : (allQuery.region3 + '度'))) + allQuery.region1,
      deptCode: allQuery.region,
      startDate: allQuery.region3 == '年' ? (this.$moment(allQuery.date1).startOf("year").format('YYYY-MM-DD HH:mm:ss')) : (this.$moment(allQuery.date1).startOf("month").format('YYYY-MM-DD HH:mm:ss')),
      endDate: allQuery.region3 == '年' ? (this.$moment(allQuery.date1).endOf("year").format('YYYY-MM-DD HH:mm:ss')) : (this.$moment(allQuery.date1).endOf("month").format('YYYY-MM-DD HH:mm:ss')),
      queryType: 'month',
    }
    if (allQuery.region3 == '月') {
      this.objAll["queryType"] = "month"
    } else if (allQuery.region3 == '年') {
      this.objAll["queryType"] = "year"
    } else if (allQuery.region3 == '天') {
      this.objAll["queryType"] = "day"
      this.objAll.startDate = this.$moment(allQuery.date1).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.objAll.endDate = this.$moment(allQuery.date1).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
    this.getAlldata22()
    this.getAlldata33()
    this.getAlldata44()
    this.getAlldata4()
    this.getAlldata5()
    this.getAlldata()
    this.getAllpeople()
  },
  mounted() {
    // const xxx = document.getElementById('class_pdf')  // 需要导出部分页面的id名
    // this.pdfDownLoader = new PdfLoader(xxx, 'fileName', 'question-table')  // fileName -->导出文件名,  question-table -->防止被截断的class名
  },
  methods: {
    handleOutput() {
      let _this = this;
      const element = document.querySelector('.pdf-panel');
      const header = document.querySelector('.pdf-header');
      const footer = document.querySelector('.pdf-footer');
      outputPDF({
        element: element,
        footer: footer,
        header: header,
        contentWidth: 500,
        filename: '运维报告',
        _this: _this,
        dev_url: Config.dev_url
      })
    },
    getAllpeople() {
      let data = {
        "workOrderType": "维修工单",
        "deptCode": this.objAll.deptCode,
        "startTime": this.objAll.startDate,
        "endTime": this.objAll.endDate,
        "queryType": this.objAll.queryType
      }
      let url = Config.dev_url + "/api-apps/WorkOrderList/people/top";
      this.$axios.post(url, data).then((res) => {
        let objAll = res.data.data
        this.tableData = objAll
        this.tableData1 = objAll
        this.tableData2 = objAll
        this.initEchart(objAll)
        this.initEchart13(objAll)
        this.initEchart21(objAll)
      });
    },
    // handleExport() {
    //   this.pdfDownLoader.outPutPdfFn('运维报告')
    //   let vm = this
    //   setTimeout(() => {
    //     vm.uploadreport()
    //   }, 10000);
    // },
    getAlldata() {
      let data = { "equipment": this.objAll.deptCode, "type": this.objAll.queryType, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate }
      let url = Config.dev_url + "/api-apps/WorkOrder/orderType";
      this.$axios.post(url, data).then((res) => {
        let dataobj = res.data
        this.tableData3.push({
          namecode: '巡检工单',
          name: dataobj.xunJian,
          type: '',
          site: ''
        }, {
          namecode: '维保工单',
          name: dataobj.weiBao,
          type: '',
          site: ''
        },
          {
            namecode: '维修工单',
            name: dataobj.weiXiu,
            type: '',
            site: ''
          })
      });
    },
    getAlldata22() {
      let data = { "equipment": this.objAll.deptCode, "limit": 5, "page": 1, "queryType": this.objAll.queryType, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate }
      let url = Config.dev_url + "/api-apps/WorkOrder/OrderRanking"; ///api-apps/WorkOrder/orderType";
      this.$axios.post(url, data).then((res) => {
        this.data2 = res.data.data;
        this.initEchart22()

        this.initEchart23()
        // 
        this.initEchart1()
        this.initEchart2()
        // 
        this.initEchart12()
        this.initEchart14()
      });
    },
    getAlldata33() {
      let data = { "equipment": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "dateType": this.objAll.queryType == 'month' ? '本月' : (this.objAll.queryType == 'year' ? '本年' : '今日') }
      let url = Config.dev_url + "/api-apps/WorkOrder/orderOverview";
      this.$axios.post(url, data).then((res) => {
        this.sumCountData = res.data;
        this.initEchart33()
      });
    },
    getAlldata44() {
      let data = { "deptCode": this.objAll.deptCode }
      let url = Config.dev_url + "/api-apps/WorkOrderList/queryOrderCountByMonth";
      this.$axios.post(url, data).then((res) => {
        this.curData = res.data.data;
        this.initEchart44()
      });
    },
    getAlldata4() {
      let data = { "deptCode": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "dateType": this.objAll.queryType == 'month' ? '本月' : (this.objAll.queryType == 'year' ? '本年' : '今日') }
      let url = Config.dev_url + "/api-apps/eventInfo/statsByStatus";
      this.$axios.post(url, data).then((res) => {
        this.valueData = res.data.data;
        this.initEchart4()
      });
    },
    sumList(arr) {
      return arr.reduce(function (prev, curr, idx, arr) {
        return Number(prev == 'null' ? null : prev) + Number(curr == 'null' ? null : curr);
      });
    },
    getAlldata5() {
      let data = { "deptCode": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType }
      let url = Config.dev_url + "/api-apps/eventInfo/statsByEventType";
      this.$axios.post(url, data).then((res) => {
        this.typeCntData = res.data.data;
        let obj = res.data.data.seriesData
        obj.forEach((item) => {
          if (item.eventType == '常规') {
            this.tableData4.push({
              namecode: item.eventType + '（项）',
              name: this.sumList(item.value),
              type: '',
              site: ''
            })
          }
        })
        this.initEchart5()
      });
    },
    // 饼图
    initEchart(objAll) {
      let chartInit = echarts.init(this.$refs.chart2, "chart2");
      var syjgdata = [];
      var objAlls = objAll || []
      objAlls.forEach((item) => {
        syjgdata.push({
          name: item.createbyname,
          value: item.wocount
        })
      })
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
          data: syjgdata,
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    // 折线
    initEchart1() {
      let chartInit = echarts.init(this.$refs.chart1, "chart1");
      var getsjjg = this.data2
      var getsjjgrs = [];
      var data1 = []

      getsjjg.forEach((item) => {
        getsjjgrs.push(item.createbyname)
        data1.push(item.a)
      })
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: getsjjgrs
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          type: 'line',
          name: '平均工时',
          data: data1
        }]
      }

      chartInit.setOption(option13);
    },
    // 折线
    initEchart2() {
      let chartInit = echarts.init(this.$refs.chart3, "chart3");
      var getsjjg = this.data2
      var getsjjgrs = [];
      var data1 = []

      getsjjg.forEach((item) => {
        getsjjgrs.push(item.createbyname)
        data1.push(item.a)
      })
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: getsjjgrs
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          type: 'line',
          name: '平均工时',
          data: data1
        }]
      }

      chartInit.setOption(option13);
    },

    // 折线
    initEchart12() {
      let chartInit = echarts.init(this.$refs.chart12, "chart12");
      var getsjjg = this.data2
      var getsjjgrs = [];
      var data1 = []

      getsjjg.forEach((item) => {
        getsjjgrs.push(item.createbyname)
        data1.push(item.a)
      })
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: getsjjgrs
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          type: 'line',
          name: '平均工时',
          data: data1
        }]
      }

      chartInit.setOption(option13);
    },
    // 饼图
    initEchart13(objAll) {
      let chartInit = echarts.init(this.$refs.chart13, "chart13");
      var syjgdata = [];
      var objAlls = objAll || []
      objAlls.forEach((item) => {
        syjgdata.push({
          name: item.createbyname,
          value: item.wocount
        })
      })
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
          data: syjgdata,
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    // 折线
    initEchart14() {
      let chartInit = echarts.init(this.$refs.chart14, "chart14");
      var getsjjg = this.data2
      var getsjjgrs = [];
      var data1 = []

      getsjjg.forEach((item) => {
        getsjjgrs.push(item.createbyname)
        data1.push(item.a)
      })
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: getsjjgrs
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          type: 'line',
          name: '平均工时',
          data: data1
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart23() {
      let chartInit = echarts.init(this.$refs.chart23, "chart23");
      var getsjjg = this.data2
      var getsjjgrs = [];
      var data1 = []

      getsjjg.forEach((item) => {
        getsjjgrs.push(item.createbyname)
        data1.push(item.a)
      })
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: getsjjgrs
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          type: 'line',
          name: '平均分',
          data: data1
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart21(objAll) {
      let chartInit = echarts.init(this.$refs.chart21, "chart21");
      var syjgdata = [];
      var objAlls = objAll || []
      objAlls.forEach((item) => {
        syjgdata.push({
          name: item.createbyname,
          value: item.wocount
        })
      })
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
          data: syjgdata,
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart22() {
      let chartInit = echarts.init(this.$refs.chart22, "chart22");
      var getsjjg = this.data2
      var getsjjgrs = [];
      var data1 = []

      getsjjg.forEach((item) => {
        getsjjgrs.push(item.createbyname)
        data1.push(item.a)
      })
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: getsjjgrs
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          type: 'line',
          name: '平均工时',
          data: data1
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart33() {
      let chartInit = echarts.init(this.$refs.chart33, "chart33");
      var syjgdata = [];
      Object.keys(this.sumCountData).forEach((item) => {
        if (item == 'ywc') {
          syjgdata.push({
            name: '已完成',
            value: this.sumCountData['ywc']
          })
        }
        if (item == 'wqd') {
          syjgdata.push({
            name: '未启动',
            value: this.sumCountData['wqd']
          })
        }
        if (item == 'yqd') {
          syjgdata.push({
            name: '已启动',
            value: this.sumCountData['yqd']
          })
        }
      })
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
          data: syjgdata,
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart44() {
      let chartInit = echarts.init(this.$refs.chart44, "chart44");
      var getsjjg = this.curData.curYearMonthValList
      var getsjjgrs = this.curData.curYearMonthList
      var syjgdata = [
        {
          type: 'line',
          name: '工单量',
          data: getsjjg
        }
      ];

      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: getsjjgrs
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        yAxis: {
          type: 'value'
        },
        series: syjgdata
      }

      chartInit.setOption(option13);
    },
    initEchart4() {
      let chartInit = echarts.init(this.$refs.chart4, "chart4");
      var getsjjg = this.valueData.valueList
      var syjgdata = [];
      getsjjg.forEach((item) => {
        syjgdata.push({
          name: item.alertstatus,
          value: item.cnt
        })
      })
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
          data: syjgdata,
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart5() {
      let chartInit = echarts.init(this.$refs.chart5, "chart5");
      var getsjjg = this.typeCntData.seriesData
      var getsjjgrs = this.typeCntData.dateList
      var syjgdata = [];
      getsjjg.forEach((item) => {
        syjgdata.push({
          type: 'line',
          name: item.eventType,
          data: item.value
        })
      });
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: getsjjgrs
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        yAxis: {
          type: 'value'
        },
        series: syjgdata
      }

      chartInit.setOption(option13);
    },
  }
}
</script>
<style lang="less">
.opexReport {
  background: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

#maintablepage {
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
     width: calc(100% - 40px);
}

#maintablepage .maintablepage-content .maintablepage-header {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .maintablepage-header-name {
    font-size: 38px;
  }
}

#maintablepage .maintablepage-content {
  flex: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.app-table .el-table__header thead tr th {
  padding: 0;
  height: 40px;
  background: #f2f3f5;
}
</style>
<style lang="less" scoped>
#maintablepage .maintablepage-content {
  overflow-y: auto !important;
}

.contant_O {
  overflow-y: scroll;
  position: relative;
}

.cont_z {
  padding: 10px 0;
  font-size: 30px;
}

.marStyle {
  margin: 20px;
}

.msrStr {
  margin: 20px 0;
}

/deep/.app-table .el-table__header thead tr th .cell {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}

/deep/.app-table .el-table__body tbody tr td .cell {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}
</style>